import React from "react"
import Layout from "../components/layout"
import { dataPages } from "../../data/dataPages"
import styled from "styled-components"
import { navigate } from "gatsby"

const Card = styled.div`
  background-color: #0084d0;
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  font-size: 2rem;
  text-align: center;
  cursor: pointer;
  transition-property: background-color;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  &:hover {
    background-color: #23aaf2;
  }
`

const IndexPage = props => {
  const handleGoToPage = url => {
    navigate(`/${url}`)
  }

  const mapCard = dataPages.map(item => {
    return (
      <div className="col-md-6 col-12 p-2" key={item.id}>
        <Card onClick={() => handleGoToPage(item.page)}>{item.title}</Card>
      </div>
    )
  })
  return (
    <Layout pathname={props.location.pathname}>
      <div className="container mt-5 pt-5">
        <div className="row">{mapCard}</div>
      </div>
    </Layout>
  )
}

export default IndexPage
